<template>
  <div>
    <b-card title="Araç Bilgisi">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <b-row>
            <b-col>
              <b-list-group>
                <list-item
                  title="Marka"
                  class="border-0"
                  :value="lineData[dataKey].brand"
                />
              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group>
                <list-item
                  title="Model"
                  class="border-0"
                  :value="lineData[dataKey].model"
                />
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-list-group>
                <list-item
                  title="Araç Tanımı"
                  class="border-0"
                  :value="lineData[dataKey].priceCar"
                />
              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group>
                <list-item
                  title="Model Yılı"
                  class="border-0"
                  :value="lineData[dataKey].myear"
                />
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-list-group>
                <b-list-group-item class="border-0">
                  <div class="font-weight-bold text-primary border-0">
                    Liste Fiyatı
                  </div>
                  {{ lineData[dataKey].listed_price }} TL
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group>
                <b-list-group-item class="border-0">
                  <div class="font-weight-bold text-primary border-0">
                    Teklif Fiyatı
                  </div>
                  {{ lineData[dataKey].offer_price }} TL
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-list-group>
                <list-item
                  title="Dış Renk"
                  class="border-0"
                  :value="lineData[dataKey].exterior_color"
                />
              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group>
                <list-item
                  title="İç Renk"
                  class="border-0"
                  :value="lineData[dataKey].interior_color"
                />
              </b-list-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-img
            v-if="lineData[dataKey].image"
            width="250px"
            :src="'https://inallar.com.tr/media/color/' + lineData[dataKey].image"
          />
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BImg, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import ListItem from '@/views/Customers/view/ListItem.vue'

export default {
  name: 'OfferViewLine',
  components: {
    BListGroupItem,
    BListGroup,
    ListItem,

    BCard,
    BRow,
    BCol,
    BImg,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const LineData = this.$store.getters['offers/getOffer']
      return LineData.lines
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('offers/offerView', this.$route.params.id)
    },
  },
}
</script>
